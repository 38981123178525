/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import '~swiper/scss';
@import '~@ionic/angular/css/ionic-swiper';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;1,400&display=swap');
@import '~codemirror/lib/codemirror';
@import '~codemirror/theme/material';


@font-face {   
    font-family: 'Optima';    //This is what we are going to call
    src: url('assets/fonts/Optima/Optima\ LT\ Std\ Medium.otf');
}
* {
    font-family: 'Optima';
}

.swiper-slide ion-grid{
    visibility: hidden;
}

.swiper-slide.swiper-slide-active ion-grid{
    visibility: visible;
}

.inner-text{
    margin-top: 5px;
}

::-webkit-scrollbar-track{
    box-shadow: inset 0 0 6px rgb(0 0 0 / 30%) !important;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%) !important;
    background-color: white !important;
}

::-webkit-scrollbar {
    width: 0px !important;
    height: 10px !important;
    background-color: #ccb062 !important;
}

::-webkit-scrollbar-thumb{    
    border-radius: 5px !important;
    box-shadow: inset 0 0 6px rgb(0 0 0 / 30%) !important;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%) !important;
    background-color: #ccb062 !important;
}

.flex-end-horizontal {
    margin-left: auto;
}

.content-body {
    padding: 30px;
}
.separator {
    height: 1px;
    background-color: var(--ion-color-dark);
}
.section-header {
    display: flex;
    justify-content: space-between;
    ion-button {
        align-self: center;
    }
}
.back-link {
    cursor: pointer;
    color: var(--ion-color-dark);
    margin: 10px 10px 0;
    display: flex;
}

.formula-variable {
    display: inline-block;
    background: lightblue;
}

.select-alert {
    --max-width: unset !important;
    --width: 500px !important
} 

.multiple-select-alert {
    --max-height: unset !important;
}
.CodeMirror-hscrollbar {
    display: none !important
}
.CodeMirror-scroll {
    overflow: hidden !important;
    padding-bottom: 0;
    .CodeMirror-sizer {
        height: 38px;
        position: relative;
        top: 0;
        .CodeMirror-lines {
            height: 38px; 
        }
    }
}
.CodeMirror {
    width: 100%;
    height: 38px
}